import React from "react";

import logo from "../assets/img/logo.png";

const Navbar = () => {
  return (
    <nav className='navbar navbar-expand-md navbar-dark fixed-top'>
      <div className='container'>
        <a className='navbar-brand' href='/'>
          <img className='img-fluid logo' src={logo} alt='' loading='lazy' />
          <span> PediatrikHesaplar</span>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
