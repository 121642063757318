import React from "react";

import Base from "../../Base";

const TPN = () => {
  return (
    <Base>
      <div className='row'>
        <div className='col-md-7 mb-4'>
          <h4>Parametreler</h4>
          <div className='form-group row'>
            <label htmlFor='weight' className='col-sm-5 col-form-label'>
              Kilo (kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='weigth'
                min='0.4'
                max='20'
                step='0.001'
                defaultValue='2'
                pattern='[0-9]{2},[0-9]{3}'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='infusion_rate' className='col-sm-5 col-form-label'>
              Sıvı (ml/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='infusion_rate'
                min='1'
                max='300'
                step='1'
                defaultValue='100'
                pattern='[0-9]{3}'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='gph' className='col-sm-5 col-form-label'>
              GPH (mg/kg/dk)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='gph'
                min='1'
                max='100'
                step='0.1'
                defaultValue='4'
                pattern='[0-9]{3},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
            </div>
          </div>
          <hr />

          <div className='form-group row'>
            <label htmlFor='ss' className='col-sm-5 col-form-label'>
              Serum Sale (mEq/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='ss'
                min='0'
                max='10'
                step='0.01'
                defaultValue='0'
                aria-describedby='sshelp'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <small id='sshelp' className='form-text text-muted'>
                1ml Serum Sale 3,4mEq Sodyum içerir.
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='kcl' className='col-sm-5 col-form-label'>
              Potasyum Klorür (mEq/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='kcl'
                min='0'
                max='10'
                step='0.01'
                defaultValue='0'
                aria-describedby='kclhelp'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <small id='kclhelp' className='form-text text-muted'>
                1ml %7,5 KCl 1mEq potasyum içerir.
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='kpo4' className='col-sm-5 col-form-label'>
              Potasyum Fosfat (mEq/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='kpo4'
                min='0'
                max='10'
                step='0.01'
                defaultValue='0'
                aria-describedby='kpo4help'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <div className='invalid-feedback'>Çökelme!</div>
              <small id='kpo4help' className='form-text text-muted'>
                1ml KPO4 0,6mEq Fosfor ve 1mEq potasyum içerir. Toplam potasyum
                miktarına dikkat edin.
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='glycophos' className='col-sm-5 col-form-label'>
              Glycophos (mEq/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='glycophos'
                min='0'
                max='10'
                step='0.01'
                defaultValue='0'
                aria-describedby='glycophoshelp'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <div className='invalid-feedback'>Çökelme!</div>
              <small id='glycophoshelp' className='form-text text-muted'>
                1ml Glycophos 1mEq Fosfor ve 2mEq Sodyum içerir. Toplam sodyum
                ve fosfor miktarlarına dikkat edin.
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='caglukonat' className='col-sm-5 col-form-label'>
              Kalsiyum Glukonat (mEq/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='caglukonat'
                min='0'
                max='10'
                step='0.01'
                defaultValue='0'
                aria-describedby='caglukonathelp'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <div className='invalid-feedback'>Çökelme!</div>
              <small id='caglukonathelp' className='form-text text-muted'>
                1ml Kalsiyum Glukonat 0,5mEq kalsiyum içerir.
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='mgso4' className='col-sm-5 col-form-label'>
              Magnezyum Sulfat (mEq/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='mgso4'
                min='0'
                max='10'
                step='0.01'
                defaultValue='0'
                aria-describedby='mgso4help'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <small id='mgso4help' className='form-text text-muted'>
                1ml Magnezyum Sulfat 1,2mEq magnezyum içerir.
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='tracutil' className='col-sm-5 col-form-label'>
              Tracutil (ml/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='tracutil'
                min='0,5'
                max='1'
                step='0.01'
                defaultValue='0'
                aria-describedby='tracutilhelp'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <small id='tracutilhelp' className='form-text text-muted'>
                0,5-1ml/kg/gün
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='addamel' className='col-sm-5 col-form-label'>
              Addamel (ml/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='addamel'
                min='0,25'
                max='0,5'
                step='0.01'
                defaultValue='0'
                aria-describedby='addamelhelp'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <small id='addamelhelp' className='form-text text-muted'>
                0,25-0,5ml/kg/gün
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='soluvit' className='col-sm-5 col-form-label'>
              Soluvit (ml/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='soluvit'
                min='0'
                max='10'
                step='0.01'
                defaultValue='0'
                aria-describedby='soluvithelp'
                pattern='[0-9]{1},[0-9]{2}'
              />
              <small id='soluvithelp' className='form-text text-muted'>
                1ml/kg/gün (max 10ml/gün)
              </small>
            </div>
          </div>
          <hr />

          <div className='form-group row'>
            <label htmlFor='primene' className='col-sm-5 col-form-label'>
              Primene (gr/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='primene'
                min='0'
                max='10'
                step='0.1'
                defaultValue='0'
                aria-describedby='primenehelp'
                pattern='[0-9]{1},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
              <small id='primenehelp' className='form-text text-muted'>
                %10'luk aminoasit çözeltisi
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='trophamine' className='col-sm-5 col-form-label'>
              Trophamine (gr/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='trophamine'
                min='0'
                max='10'
                step='0.1'
                defaultValue='0'
                aria-describedby='trophaminehelp'
                pattern='[0-9]{1},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
              <small id='trophaminehelp' className='form-text text-muted'>
                %6'lık aminoasit çözeltisi
              </small>
            </div>
          </div>
          <hr />

          <div className='form-group row'>
            <label htmlFor='omegaven' className='col-sm-5 col-form-label'>
              Omegaven (gr/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='omegaven'
                min='0'
                max='10'
                step='0.1'
                defaultValue='0'
                aria-describedby='omegavenhelp'
                pattern='[0-9]{1},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
              <small id='omegavenhelp' className='form-text text-muted'>
                %10'luk lipid çözeltisi
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='smoflipid' className='col-sm-5 col-form-label'>
              SMOFLipid (gr/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='smoflipid'
                min='0'
                max='10'
                step='0.1'
                defaultValue='0'
                aria-describedby='smoflipidhelp'
                pattern='[0-9]{1},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
              <small id='smoflipidhelp' className='form-text text-muted'>
                %20'lik lipid çözeltisi
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='clinoleic' className='col-sm-5 col-form-label'>
              Clinoleic (gr/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='clinoleic'
                min='0'
                max='10'
                step='0.1'
                defaultValue='0'
                aria-describedby='clinoleichelp'
                pattern='[0-9]{1},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
              <small id='clinoleichelp' className='form-text text-muted'>
                %20'lik lipid çözeltisi
              </small>
            </div>
          </div>
          <div className='form-group row'>
            <label htmlFor='intralipid' className='col-sm-5 col-form-label'>
              Intralipid (gr/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='intralipid'
                min='0'
                max='10'
                step='0.1'
                defaultValue='0'
                aria-describedby='intralipidhelp'
                pattern='[0-9]{1},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
              <small id='intralipidhelp' className='form-text text-muted'>
                %20'lik lipid çözeltisi
              </small>
            </div>
          </div>

          <div className='form-group row'>
            <label htmlFor='vitalipid' className='col-sm-5 col-form-label'>
              Vitalipid (ml/kg)
            </label>
            <div className='col-sm-7'>
              <input
                type='number'
                className='form-control'
                id='vitalipid'
                min='0'
                max='10'
                step='0.1'
                defaultValue='0'
                aria-describedby='vitalipidhelp'
                pattern='[0-9]{1},[0-9]{1}'
              />
              <div className='invalid-feedback'> </div>
              <small id='vitalipidhelp' className='form-text text-muted'>
                4ml/kg/gün (max 10ml/gün)
              </small>
            </div>
          </div>

          <div className='form-group row'>
            <label htmlFor='vitalipid' className='col-sm-5 col-form-label'>
              Hazırlanma miktarı
            </label>
            <div className='col-sm-7'>
              <div className='form-check form-check-inline' id='multiple'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='multipleOptions'
                  id='x1'
                  defaultValue='1'
                />
                <label className='form-check-label' htmlFor='x1'>
                  1x
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='multipleOptions'
                  id='x15'
                  defaultValue='1.5'
                />
                <label className='form-check-label' htmlFor='x15'>
                  1,5x
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='multipleOptions'
                  id='x2'
                  defaultValue='2'
                />
                <label className='form-check-label' htmlFor='x2'>
                  2x
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-5 mb-4'>
          <h4>İçerik</h4>
          <p>
            <b>Toplam sıvı:</b> <span id='totalvolume'></span> ml
          </p>
          <p>
            <b>Toplam Dextroz:</b> <span id='totaldex'></span> gr | %
            <span id='totaldexpercent'></span>
          </p>
          <p>
            <b>Toplam Protein:</b> <span id='totalprotein'></span> gr | %
            <span id='totalproteinpercent'></span>
          </p>
          <p>
            <b>Toplam Lipid:</b> <span id='totallipid'></span> gr | %
            <span id='totallipidpercent'></span>
          </p>
          <p>
            <b>Toplam Sodyum:</b> <span id='totalnatrium'></span> mEq/kg
          </p>
          <p>
            <b>Toplam Potasyum:</b> <span id='totalpotassium'></span> mEq/kg
          </p>
          <p>
            <b>Toplam Fosfor:</b> <span id='totalphosphor'></span> mEq/kg
          </p>
          <p>
            <b>Dextroz:</b> <span id='dexvolume'></span> ml | %
            <span id='dexpercent'></span>
          </p>
          <p>
            <b>Çökelme Faktörü:</b> <span id='sedimentationfactor'></span>
          </p>
          <p>
            <b>Dextroz Kalorisi:</b> <span id='dexkcal'></span> kcal
          </p>
          <p>
            <b>Protein Kalorisi:</b> <span id='proteinkcal'></span> kcal
          </p>
          <p>
            <b>Lipid Kalorisi:</b> <span id='lipidkcal'></span> kcal
          </p>
          <p>
            <b>Toplam Kalori:</b> <span id='totalkcal'></span> kcal |{" "}
            <span id='kgkcal'></span> kcal/kg
          </p>
          <p>
            <b>Protein/Kalori:</b> <span id='protein_cal'></span>
          </p>
          <p>
            <b>Non-protein Kalori/Azot:</b>{" "}
            <span id='nonprotein_nitrogen'></span>
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-7 mb-4'>
          <h4>Reçete</h4>
          <p id='receipt'>
            <b className='mb-1'>Reçetenin doğruluğunu kontrol ediniz!</b>
          </p>
        </div>
      </div>
    </Base>
  );
};

export default TPN;
