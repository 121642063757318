import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Main from "../pages/Main/Main";
import TPN from "../pages/Calculators/TPN/TPN";
import Disclaimer from "../pages/Flatpages/Disclaimer";
import Privacy from "../pages/Flatpages/Privacy";
import TOS from "../pages/Flatpages/TOS";

const Navigation = () => {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL.*/}
      <Switch>
        <Route exact path='/hesaplayıcı/tpn'>
          <TPN />
        </Route>
        <Route exact path='/disclaimer'>
          <Disclaimer />
        </Route>
        <Route exact path='/privacy'>
          <Privacy />
        </Route>
        <Route exact path='/tos'>
          <TOS />
        </Route>
        <Route path='/'>
          <Main />
        </Route>
      </Switch>
    </Router>
  );
};

export default Navigation;
