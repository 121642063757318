import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faTwitter,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faStar,
  faPlus,
  faEnvelope,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

const importFA = () => {
  library.add(
    fab,
    far,
    faBars,
    faStar,
    faPlus,
    faEnvelope,
    faTwitter,
    faChevronUp,
    faLinkedinIn
  );
};

export default importFA;
