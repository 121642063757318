import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [test, setTest] = useState(0);

  return (
    <AppContext.Provider
      value={{
        test: [test, setTest]
      }}>
      {props.children}
    </AppContext.Provider>
  );
};
