import React from "react";

import Item from "./Item";

import Base from "../Base";

const Main = () => {
  const itemList = [["/tpn", "TPN"]];
  const items = itemList.map((i) => (
    <div className='col mb-4'>
      <Item item={i} />
    </div>
  ));

  return (
    <Base>
      <div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6'>
        {items}
      </div>
    </Base>
  );
};

export default Main;
