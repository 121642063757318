import React from "react";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className='mt-auto py-3'>
      <div className='container text-muted text-center text-small'>
        <p className='mb-1'>&copy; 2019-2020 Birkan ÖZER</p>
        <ul className='list-inline'>
          <li className='list-inline-item'>
            <Link to='/tos'>Şartlar</Link>
          </li>
          <li className='list-inline-item'>
            <Link to='/disclaimer'>Feragatname</Link>
          </li>
          <li className='list-inline-item'>
            <Link to='/privacy'>Gizlilik</Link>
          </li>
          <li className='list-inline-item'>
            <a href='https://birkanozer.tech'>Destek</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
