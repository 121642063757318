import React from "react";

import Navbar from "./Navbar";
import Footer from "./Footer";

const Base = (props) => {
  return (
    <>
      <Navbar />
      <main className='container'>{props.children}</main>
      <Footer />
    </>
  );
};

export default Base;
