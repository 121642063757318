import React from "react";

import { Link } from "react-router-dom";

const Item = (props) => {
  const link = "/hesaplayıcı" + props.item[0];
  const name = props.item[1];

  return (
    <div className='card text-center'>
      <Link to={link}>
        <div className='card-body'>
          <h5 className='card-text'>{name}</h5>
        </div>
      </Link>
    </div>
  );
};

export default Item;
