import React from "react";

import { AppContextProvider } from "./context/appContext";

import Navigation from "./navigation/Navigation";
import importFA from "./utils/importFA";

import "bootstrap/dist/css/bootstrap.css";

importFA();

const App = () => {
  return <Navigation />;
};

export default () => {
  return (
    <AppContextProvider>
      <App />
    </AppContextProvider>
  );
};
